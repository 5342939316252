import React, {FC, useState} from 'react';
import {Popconfirm, Table, Modal, Skeleton, Tag, Avatar, Image, Rate, List, Alert} from 'antd';
import dayjs from "dayjs";
import {useDeleteDriverMutation} from "../../redux/services/driversApi";
import {handleNotification} from "../../utils/helpers";
import {MdCircle} from "react-icons/md";
import MainApi from "../../services/axiosInstance";

interface DriverItemProps {
    _id: string
    createdAt: Date
    averageRating: number
    phone: string
    status: string
    isAvailable: boolean
    gender: string
    idNumber: string
    city: string
    country: string
    firstName: string
    photo: string
    surName: string
    address: {
        erf: string
        suburb: string
        street: string
    }
}


interface DriversDataProps {
    data: DriverItemProps[]
    refetchDrivers: any
    loading: boolean
    onOpenEdit?: any
}

interface DriverProps {
    totalShipments: number
    totalOrders: number
    totalEarnings: number
    deliveredOrders: string
    _id: string
}

const DriversTable: FC<DriversDataProps> = ({data, loading, onOpenEdit, refetchDrivers}) => {
    const [deleteDriver, {isLoading: deletingDriver}] = useDeleteDriverMutation()
    const [showViewDriver, setShowViewDriver] = useState(false)
    const [driver, setDriver] = useState<DriverProps | undefined>(undefined)
    const [driverDetails, setDriverDetails] = useState<DriverItemProps | undefined>(undefined)
    const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    const [changingStatus, setChangingStatus] = useState(false)
    const [statusResponseType, setStatusResponseType] = useState<'warning' | 'success' | 'error'>('success')
    const [statusMessage, setStatusMessage] = useState('')
    const handleDelete = (driverId: string) => {
        deleteDriver({driverId}).then((res: any) => {
            if (res.data?.message === 'success') {
                handleNotification('success')
            } else {
                handleNotification('error')
            }
        })
    };

    const handleCloseViewDriver = () => {
        setShowViewDriver(false)
    }

    const handleChangeDriverStatus = async (id?: string, status?: string) => {
        if (id) {
            setChangingStatus(true)
            try {
                await MainApi.patch(`/drivers/${id}/status`, {status: status}).then((response) => {
                    if (response && response.data) {
                        setDriverDetails(response.data.result)
                        setStatusResponseType('success')
                        setStatusMessage(`Successfully ${status} ${driverDetails?.firstName}.`)
                        refetchDrivers()
                        setTimeout(() => {
                            setShowDeleteWarning(false)
                            setStatusMessage('')
                        }, 2000)
                    }
                })
            } catch (e: any) {
                setStatusMessage(e.response.data.message)
                setStatusResponseType('error')
            } finally {
                setChangingStatus(false)
            }
        }
    }

    const handleShowWarning = (id: string) => {
        if (id) {
            setStatusMessage('')
            setShowDeleteWarning(true)
        }
    }

    const handleViewDriver = async (row: DriverItemProps) => {
        setShowViewDriver(true)
        setDriver(undefined)
        setDriverDetails(row)
        if (row) {
            await MainApi.get(`/drivers/statistics?driverId=${row._id}`).then((response) => {
                if (response && response.data.message === 'success') {
                    setDriver(response.data.result)
                }
            })
        }
    }

    const columns = [
        {
            title: '',
            width: 60,
            render: (_: any, row: DriverItemProps) => {
                return (
                    <>
                        {row.photo ? (<Image src={row.photo} width={42} height={42}
                                             style={{borderRadius: "50%", objectFit: 'cover'}}/>) :
                            <Avatar style={{backgroundColor: '#2D4669'}}
                                    size="large">{row.firstName.charAt(0)}</Avatar>}
                    </>
                )
            }
        },
        {
            title: 'Names',
            width: 140,
            render: (_: any, row: DriverItemProps) => {
                return (
                    <span>{row.firstName + ' ' + row.surName}</span>
                )
            }
        },
        {
            dataIndex: 'status',
            title: 'Status',
            width: 200,
            render: (_: any, row: DriverItemProps) => {
                return (
                    <Tag color={row.status === 'VERIFIED' ? 'success' : 'processing'}>{row.status}</Tag>
                )
            }
        },
        {
            dataIndex: 'phone',
            title: 'Phone',
            width: 160,
        },
        {
            dataIndex: 'averageRating',
            title: 'Average rating',
            render: (_: any, row: DriverItemProps) => {
                return (
                    <div>
                        <Rate disabled style={{fontSize: "12px"}} allowHalf value={row.averageRating}/>
                    </div>
                )
            }
        },
        // {
        //     title: 'Street',
        //     render: (_: any, row: DriverItemProps) => {
        //         return (
        //             <span>{row.address?.street || 'Unknown'}</span>
        //         )
        //     }
        // },
        // {
        //     dataIndex: 'gender',
        //     title: 'Gender',
        // },
        {
            dataIndex: 'createdAt',
            title: 'Created at',
            width: 140,
            render: (_: any, row: DriverItemProps) => {
                return (
                    <span>{dayjs(row.createdAt).format('MMMM D, YYYY')}</span>
                )
            }
        },
        {
            title: 'Actions',
            render: (_: any, row: DriverItemProps) => {
                return (
                    <div className="space-x-3 flex flex-nowrap">
                        <Popconfirm okType="danger" title="Are you sure for deleting this driver?"
                                    onConfirm={() => handleDelete(row._id)}>
                            <button className="delete_button">{deletingDriver ? <MdCircle className="animate-ping"/>
                                : 'Delete'}</button>
                        </Popconfirm>
                        <button className="edit_button" onClick={() => onOpenEdit(row._id)}>
                            Edit
                        </button>
                        <button className="view_button" onClick={() => handleViewDriver(row)}>
                            More
                        </button>
                        <Modal
                            title={driverDetails?.firstName}
                            open={showViewDriver} onCancel={handleCloseViewDriver}
                            footer={null}
                        >
                            {driver ? (
                                <div>
                                    <div className="mb-5 bg-gray-50 rounded p-4 grid grid-cols-1 md:grid-cols-2 gap-2">
                                        <List.Item>
                                            <List.Item.Meta
                                                title="Phone:"
                                                description={driverDetails?.phone}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title="Country:"
                                                description={driverDetails?.country}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title="Country:"
                                                description={driverDetails?.city}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title="ID Number:"
                                                description={driverDetails?.idNumber}
                                            />
                                        </List.Item>
                                    </div>
                                    <strong className="text-xl text-gray-500">Performance</strong>
                                    <div className="grid grid-cols-2 gap-4 mt-5">
                                        <div>
                                            <p className="font-bold text-gray-400">Total shipments</p>
                                            <span className="text-xl">{driver.totalShipments}</span>
                                        </div>
                                        <div>
                                            <p className="font-bold text-gray-400">Delivered orders</p>
                                            <span className="text-xl">{driver.deliveredOrders}</span>
                                        </div>
                                        <div>
                                            <p className="font-bold text-gray-400">Total orders</p>
                                            <span className="text-xl">{driver.totalOrders}</span>
                                        </div>
                                        <div>
                                            <p className="font-bold text-gray-400">Total earnings</p>
                                            <span className="text-xl">{driver.totalEarnings}</span>
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <button onClick={() => handleShowWarning(row._id)}
                                                className="primary_button">{driverDetails?.status === 'APPROVED' ? 'Suspend' : 'Activate'}
                                        </button>
                                        <Modal
                                            open={showDeleteWarning}
                                            title="Warning"
                                            okText="Confirm"
                                            confirmLoading={changingStatus}
                                            onOk={() => handleChangeDriverStatus(driverDetails?._id, driverDetails?.status === 'APPROVED' ? 'SUSPENDED' : 'APPROVED')}
                                            onCancel={() => setShowDeleteWarning(false)}
                                        >
                                            <p>Are you sure to proceed</p>
                                            {statusMessage && (
                                                <Alert type={statusResponseType} message={statusMessage}/>
                                            )}
                                        </Modal>
                                    </div>
                                </div>
                            ) : <Skeleton/>}
                        </Modal>
                    </div>
                )
            }
        }
    ]

    return (
        <div className="bg-white p-2 rounded-md">
            <Table
                dataSource={data}
                loading={loading}
                columns={columns}
                rowKey={(item: DriverItemProps) => item._id}
                scroll={{
                    x: 800,
                    y: 1200
                }}
            />
        </div>
    )
}

export default DriversTable;
