import React from "react";
import StepFormHead from "./step-form-head";
import {Card, List, ListItemText, ListItemIcon, ListItem} from "@mui/material";
import {CheckIcon} from "@heroicons/react/solid";
import staticData from "../../../utils/constants/static-texts.json";
import PrimaryButton from "../../widgets/primary-button";
import {OnboardingStepFormComponentProps} from "../../../types/onboard-forms/form-component-props";
import {Alert, Checkbox} from "antd";
import {saveStep} from "../../../utils/helpers";


const OnboardingSummary: React.FC<OnboardingStepFormComponentProps> = ({userInfo}) => {
    const [accepted, setAccepted] = React.useState(false);

    const handleAgreeTerms = () => {
        setAccepted(!accepted);
    };


    const handleFinalizeSteps = async () => {
        saveStep("DONE_ONBOARDING").then(
            () => (window.location.href = "/onboarding-home")
        );
    };

    return (
        <>
            <div>
                <StepFormHead
                    title="Summary"
                    subTitle="Please read carefully our terms and conditions and agree to finish your sign up"
                />
                <Card>
                    <List>
                        {staticData.terms_conditions.map((item: string) => (
                            <ListItem key={item}>
                                <ListItemIcon>
                                    <CheckIcon className="text-green-400 w-6"/>
                                </ListItemIcon>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Card>
                <div className="flex justify-center items-center bg-white font-bold p-5 my-4 text-gray-600">
                    <Checkbox checked={accepted} onChange={handleAgreeTerms}>
                        {`By proceeding, you automatically agree that you have read
                    carefully read our terms and conditions.`}
                    </Checkbox>
                </div>
                <div className="w-full md:w-4/12">
                    <PrimaryButton
                        buttonText="Submit"
                        type="button"
                        onClicked={handleFinalizeSteps}
                        disabled={!accepted}
                    />
                </div>
                {userInfo.onboardingStatus === "DONE_ONBOARDING" &&
                !userInfo.idDocument ? (
                    <>
                        <Alert
                            type="warning"
                            closable={false}
                            className="my-4"
                            message="Warning"
                            description="You are seeing this message, because you completed last step but some of your required
                                    documents are not provided
                                    yet. please review your information"
                        />
                    </>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};
export default OnboardingSummary;
