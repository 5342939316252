import {useAppSelector} from "../../redux/user-redux";
import {selectStepNavState} from "../../redux/steps-navigation/steps-nav-slice";
import React, {useEffect} from "react";
import DriversDetails from "../features/onboarding/steps/drivers";
import Vehicles from "../features/onboarding/steps/vehicles";
import OnboardingSummary from "../features/onboarding/summary";
import OnboardingStep1 from "./onboarding-step-1";
import OnboardingStep2 from "./onboarding-step-2";
import OnboardingStep3 from "./onboarding-step-3";
import OnboardingStep4 from "./onboarding-step-4";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {Skeleton} from 'antd'
import {UseDrivers} from "../../hooks/useDrivers";
import {UseVehicles} from "../../hooks/useVehicles";

const FormsWrapper: React.FC = () => {
    const {currentStepIndex} = useAppSelector(selectStepNavState);
    const {loading, userInfo} = useCurrentUser();
    const {loadingDrivers, drivers, refetchDrivers} = UseDrivers();
    const {loadingVehicles, vehicles, refetchVehicles} = UseVehicles()


    useEffect(() => {
        if (userInfo &&
            userInfo.onboardingStatus === "DONE_ONBOARDING"
        ) {
            window.location.href = "/onboarding-home";
        }
    }, [userInfo]);


    if (!loading) {
        switch (currentStepIndex) {
            case 0:
                return <OnboardingStep1/>;
            case 1:
                return <OnboardingStep2/>;
            case 2:
                return <OnboardingStep3/>;
            case 3:
                return <OnboardingStep4/>;
            case 4:
                return (
                    <OnboardingSummary userInfo={userInfo}/>
                );
        }
        return null;
    }
    return <Skeleton active/>
};

export default FormsWrapper;
