import Nsuburbs from './constants/namibia-suburbs.json';
import {toast} from "react-toastify";
import {faker} from "@faker-js/faker";
import moment from "moment";
import userService from "../services/user.service";

export const parseArrayToString = (value: string[]) => {
    return value.toString().replaceAll(',', '')
}

export const logoutUser = () => {
//     clear the storage
    localStorage.clear()
    window.location.href = "/login"
}
export const convertToOptions = (arr: any[], value: string, label: string) => {
    if (arr && arr.length > 0) {
        return arr.map((i) => ({
            value: i[value],
            label: i[label]
        }))
    } else {
        return []
    }
}

export const genderOptions = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'},
]

export const VehicleTypesOptions = [
    {label: 'MOTORCYCLE', value: 'Motorcycle'},
    {label: 'CAR', value: 'Car'},
    {label: 'TRUCK', value: 'Truck'},
]
export const accountTypeOptions = [
    {value: 'checking', label: 'Checking'},
    {value: 'savings', label: 'Savings'},
]

export const colorsOptions = [
    {value: 'white', label: 'White'},
    {value: 'black', label: 'Black'},
    {value: 'blue', label: 'Blue'},
    {value: 'red', label: 'Red'},
    {value: 'silver', label: 'Silver'},
    {value: 'gray', label: 'Gray'},
    {value: 'brown', label: 'Brown'},
    {value: 'beige', label: 'Beige'},
    {value: 'yellow', label: 'Yellow'},
    {value: 'green', label: 'Green'},
]

export const roleOptions = [
    {value: 'owner', label: 'Owner'},
    {value: 'driver-owner', label: 'Driver & Owner'},
]

export const namibiaSuburbs = () => {
    return Nsuburbs.map((sub: string) => (
        {value: sub, label: sub}
    ))
}

export const citiesOptions = (cities: any) => {
    return cities.map((city: { name: string }) => ({
        value: city.name, label: city.name
    }))
}


export const handleNotification = (message: string, type?: 'error' | 'success' | 'warning') => {
    if (type === 'success') {
        toast.success(message)
    }
    if (type === 'error') {
        toast.error(message)
    }
    if (type === 'warning') {
        toast.warning(message, {position: 'bottom-right', delay: 500, autoClose: 7000})
    }
}

export const createNumber = (min: number, max: number,) => {
    return faker.datatype.number({min, max})
}

export const globalDateFormat = 'YYYY-MM-DD';

export const handleOnboardingStepUpdate = (currentStepIndex: number) => {
    let step;
    switch (currentStepIndex) {
        case 0:
            step = "STEP_2"
            break;
        case 1:
            step = "STEP_3"
            break;
        case 2:
            step = "STEP_4"
            break;
        case 3:
            step = "STEP_5"
            break;
        case 4:
            step = "STEP_6"
            break;
        case 6:
            step = "DONE_ONBOARDING"
            break;

        default:
            step = "PRE_VERIFICATION"
    }
    return step
}

export const buttonPropertyFinder = (invalid: boolean, saving: boolean) => {
    const defaultDetails = {disabled: false, label: "Add new"}
    if (invalid) {
        defaultDetails.disabled = false
    }
    if (saving) {
        defaultDetails.label = "Please Wait"
    }
    return defaultDetails
}

export const getMonthName = (month: string) => {

    const last_two = month?.slice(-2)
    const monthNumber = last_two.includes('0') ? parseInt(last_two?.slice(-1)) : parseInt(last_two)

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    return monthNames[monthNumber - 1];
};


export const filterDateFormat = (value: Date) => {
    if (value) {
        return moment(value).format('YYYY-MM')
    }
}

export const saveStep = async (stepName: string) => {
    await userService.updatePartnerDetails({onboardingStatus: stepName})
}

export function isMorningOrAfternoon() {
    // Get the current time in hours.
    const currentHour = new Date().getHours();
    console.log(currentHour)

    // Check if the current hour is between 6 and 12.
    if (currentHour >= 5 && currentHour < 12) {
        return "Good morning";
    }

    // Check if the current hour is between 12 and 18.
    else if (currentHour >= 12 && currentHour < 18) {
        return "Good afternoon";
    }

    // Otherwise, it's evening.
    else {
        return "Welcome back";
    }
}

export const getPartnerCurrency = (country: 'Namibia' | 'South Africa' | 'Zambia') => {
    let currency = ''
    switch (country) {
        case "Namibia":
            currency = "N$"
            break;
        case "Zambia":
            currency = "ZK"
            break;
        case "South Africa":
            currency = "R"
            break;
    }

    return currency
}

export const disabledFutureYears = (current: any) => {
    // Get the current year
    const currentYear = moment().year();

    // Disable years that are greater than the current year
    return current && current.year() > currentYear;
};