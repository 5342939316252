import React, {FC, useEffect, useState} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import WelcomeSection from "../../Components/features/homepage/welcome-section";
import StatsSection from "../../Components/features/homepage/stats-section";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {Card, Col, Row, Tooltip} from "antd";
import {BsCheck2Circle, BsPeople, BsTruck} from "react-icons/bs";
import {UseVehicles} from "../../hooks/useVehicles";
import {UseDrivers} from "../../hooks/useDrivers";
import MainApi from "../../services/axiosInstance";
import Urls from "../../utils/constants/urls";
import {GiReceiveMoney} from "react-icons/gi";
import DriversStatsChart from "../../Components/features/drivers-stats-chart";
import {DriversStatsProps} from "../../types/stats";
import {AiOutlineFall, AiOutlineRise} from "react-icons/ai";
import {getPartnerCurrency} from "../../utils/helpers";
import images from "../../utils/constants/assets";


const Homepage: FC = () => {
    const {userInfo} = useCurrentUser();
    const {vehicles} = UseVehicles();
    const {drivers} = UseDrivers();

    const [totalShipments, setTotalShipments] = useState<number>(0)
    const [loading, setLoading] = useState(true)
    const [stats, setStats] = useState<DriversStatsProps>({
        totalShipments: 0,
        totalOrders: 0,
        totalEarnings: 0,
        deliveredOrders: 0,
        acceptedShipmentsPercentage: '',
        deliveredOrdersPercentage: ''
    })

    const [loadingDriverStats, setLoadingDriverStats] = useState(true)
    const parsePercentages = (percentage: string) => {
        const asNumber = percentage.replace("%", '')
        return parseInt(asNumber)
    }

    const fetchStats = async () => {
        try {
            await MainApi.get(`${Urls.drivers}/report`).then((response) => {
                if (response && response.data?.result) {
                    const res = response.data.result
                    setStats({
                        totalOrders: res.totalOrders,
                        totalEarnings: res.amount,
                        deliveredOrders: res.deliveredOrders,
                        totalShipments: 0,
                        deliveredOrdersPercentage: res.deliveredOrdersPercentage,
                        acceptedShipmentsPercentage: res.acceptedShipmentsPercentage
                    })
                }
            })
        } catch (e: any) {
            console.log(e)
        }
    }

    const fetchShipmentsData = async () => {
        try {
            await MainApi.get('/shipments/statistics').then((response: any) => {
                if (response && response?.data?.message === 'success') {
                    setTotalShipments(response.data.result?.totalShipments)
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchShipmentsData().then(() => setLoading(false))
        fetchStats().then(() => setLoadingDriverStats(false))
    }, [])


    const navigateTo = (path: string) => {
        if (path) {
            window.location.href = path
        }
    }

    return (
        <>
            <DashboardWrapper>
                <div className="md:px-14 px-6">
                    <WelcomeSection userInfo={userInfo}/>
                    <Row gutter={[12, 12]} className="my-4">
                        <Col xs={{span: 12}} lg={{span: 6}}>
                            <Card onClick={() => navigateTo('/vehicles')} bordered={false} className="bg-jc-primary w-full cursor-pointer hover:shadow-xl hover:scale-105 transition-all">
                                <div className="flex justify-between items-center">
                                    <BsTruck className="text-white w-10 h-10 md:w-20 md:h-20"/>
                                    <div>
                                        <h4 className="text-gray-200 mt-3">Total vehicles</h4>
                                        <h3 className="text-3xl text-white font-bold">{vehicles?.length || 0}</h3>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={{span: 12}} lg={{span: 6}}>
                            <Card onClick={() => navigateTo('/drivers')} bordered={false} className="bg-jc-primary w-full cursor-pointer hover:shadow-xl hover:scale-105 transition-all">
                                <div className="w-full flex justify-between items-center">
                                    <img src={images.drivers_icon} alt="drivers" className="text-white w-14 h-14 md:w-20 md:h-20" />
                                    <div>
                                        <h4 className="text-gray-200 mt-3">Total drivers</h4>
                                        <h3 className="text-3xl text-white font-bold">{drivers?.length}</h3>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 6}}>
                            <Card onClick={() => navigateTo('/shipment')} bordered={false} className="bg-jc-primary col-span-2 md:col-span-1 cursor-pointer hover:shadow-xl hover:scale-105 transition-all">
                                <div className="flex justify-between items-center">
                                    <BsCheck2Circle className="text-white w-20 h-20"/>
                                    <div>
                                        <h4 className="text-gray-200 mt-3">Total shipments</h4>
                                        <h3 className="text-3xl text-white font-bold">
                                            {loading ? '...' : totalShipments}
                                        </h3>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 6}}>
                            <Card onClick={() => navigateTo('/earnings')} bordered={false} className="bg-jc-primary col-span-2 md:col-span-1 cursor-pointer hover:shadow-xl hover:scale-105 transition-all">
                                <div className="flex justify-between items-center">
                                    <GiReceiveMoney className="text-white w-20 h-20"/>
                                    <div>
                                        <h4 className="text-gray-200 mt-3">Total earnings</h4>
                                        <h3 className="text-3xl text-white font-bold">
                                            {getPartnerCurrency(userInfo?.country)} {loadingDriverStats ? '...' : stats.totalEarnings?.toFixed(0)}
                                        </h3>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                        <Card title="Drivers performance">
                            <DriversStatsChart loading={loadingDriverStats} statsData={stats}/>
                        </Card>
                        <Card title="Shipment overview">
                            <StatsSection/>
                            {!loading && (
                                <div
                                    className="border-t mt-6 flex flex-col md:flex-row justify-between items-start md:items-center gap-10 md:gap-4 py-5">
                                    <div
                                        className="w-6/12 flex flex-col justify-between items-start md:justify-center md:items-center md:border-r">
                                        <p className="chart_title">Accepted shipments %</p>
                                        <div className="flex justify-start gap-3 items-center">
                                            {parsePercentages(stats.acceptedShipmentsPercentage) > 50 ?
                                                <AiOutlineRise className="w-6 h-6 text-green-500"/> :
                                                <AiOutlineFall className="w-6 h-6 text-red-500"/>}
                                            <span className="chart_value">{stats.acceptedShipmentsPercentage}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="md:w-6/12 w-full flex flex-col justify-between items-start md:justify-center md:items-center">
                                        <p className="chart_title">Delivered orders %</p>
                                        <div className="flex justify-start gap-3 items-center">
                                            {parsePercentages(stats.deliveredOrdersPercentage) > 50 ?
                                                <AiOutlineRise className="w-6 h-6 text-green-500"/> :
                                                <AiOutlineFall className="w-6 h-6 text-red-500"/>}
                                            <span className="chart_value">{stats.deliveredOrdersPercentage}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Card>
                    </div>

                </div>
            </DashboardWrapper>
        </>
    )
}

export default Homepage;