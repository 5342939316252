import {useEffect, useState} from 'react'
import driverService from "../services/driver.service";


export const UseDrivers = () => {
    const [drivers, setDrivers] = useState([])
    const [loadingDrivers, setLoadingDrivers] = useState(true)

    const fetchDrivers = async () => {
        await driverService.getAllDrivers().then((response) => {
            if (response && response.data.message === "success") {
                setDrivers(response.data.result);
            }
        });
    }

    useEffect(() => {
        fetchDrivers().then(() => setLoadingDrivers(false))
    }, [])

    const refetchDrivers = () => {
        fetchDrivers().then(() => setLoadingDrivers(false))
    }

    return {loadingDrivers, drivers, refetchDrivers}
}