import React, {FC, useEffect, useState} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import styles from './earnings.module.scss'
import {Card} from 'antd';
import ValueBox from "../../Components/widgets/value-box";
import {BarChart} from "../../Components/widgets/bar-chart";
import VehiclesView from "../../Components/features/vehicles-view";
import GlobalFilter from "../../Components/widgets/date-range-picker";
import {Skeleton} from "antd";
import {UseVehicles} from "../../hooks/useVehicles";
import MainApi from "../../services/axiosInstance";
import Urls from "../../utils/constants/urls";
import {MdMoneyOff} from "react-icons/md";
import {getPartnerCurrency} from "../../utils/helpers";
import {useCurrentUser} from "../../hooks/useCurrentUser";


interface EarningsReportProps {
    totalRevenue: number
    amountReceived: number
    amountDue: number
    monthlyRevenue: { totalRevenue: number, date: string }[]
}


const Earnings: FC = () => {
    const {userInfo} = useCurrentUser();
    const {loadingVehicles, vehicles} = UseVehicles()
    const [earningsReport, setEarningsReport] = useState<EarningsReportProps>()
    const [loadingEarningsReport, setLoadingEarningsReport] = useState(true)
    const [selectedId, setSelectedId] = useState<string | undefined>()
    const [persistedFromDate, setPersistedFromDate] = useState<string | undefined>()
    const [persistedToDate, setPersistedToDate] = useState<string | undefined>()
    const [currency, setCurrency] = useState('$N');

    const handleFetchEarningsReport = async (params?: string) => {
        try {
            await MainApi.get(Urls.earnings_report + (params ? params : '')).then((response: any) => {
                if (response && response?.data?.message === 'success') {
                    setEarningsReport(response.data.result)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }


    const handleFilter = (from?: string, to?: string, vehicleId?: string) => {
        setSelectedId(vehicleId)
        setLoadingEarningsReport(true)
        let params = ''
        if (from && to && params === '') {
            params = `?fromDate=${from}&toDate=${to}`
            setPersistedFromDate(from)
            setPersistedToDate(to)
        }
        if (vehicleId) {
            params.startsWith('?') ? params += `&vehicleId=${vehicleId}` : params += `?vehicleId=${vehicleId}`
        }
        handleFetchEarningsReport(params).then(() => setLoadingEarningsReport(false))
    }

    const resetFilterNoVehicle = () => {
        setLoadingEarningsReport(true)
        setPersistedFromDate(undefined)
        setPersistedToDate(undefined)
        handleFetchEarningsReport().then(() => setLoadingEarningsReport(false))
    }

    const resetFilterVehicle = () => {
        setLoadingEarningsReport(true)
        setSelectedId(undefined)
        handleFetchEarningsReport().then(() => setLoadingEarningsReport(false))
    }

    useEffect(() => {
        handleFetchEarningsReport().then(() => setLoadingEarningsReport(false))
    }, [])

    const getVehicle = (id: string) => {
        const found: any = vehicles.find((v: any) => v._id === id)
        if (found) {
            return found.plateNumber
        }
    }

    useEffect(() => {
        if (userInfo) {
            getPartnerCurrency(userInfo?.country)
        }
    }, []);


    return (
        <DashboardWrapper>
            <div
                className="flex justify-between flex-col md:flex-row md:items-center gap-4 mb-4 bg-white py-3 px-6 md:px-14">
                <span className="page_section_title">Earnings report {selectedId &&
                    <span className="text-jc-secondary uppercase font-medium"> - {getVehicle(selectedId)}</span>}</span>
                <GlobalFilter handleResetFilter={() => resetFilterNoVehicle()}
                              handleFilter={(from, to) => handleFilter(from, to, selectedId)}/>
            </div>
            <div className="flex justify-start gap-4 flex-col md:flex-row md:px-14 px-6">
                <div className="md:w-3/12 w-full">
                    <Card
                        loading={loadingVehicles} bordered={false}
                        title="Your garage"
                        extra={selectedId && <div onClick={resetFilterVehicle}
                                                  className="text-white bg-jc-primary py-1 px-2 rounded priam cursor-pointer">Clear
                            selection</div>}
                    >
                        {vehicles ?
                            <VehiclesView
                                selectedVehicleId={selectedId}
                                onVehicleChange={(vehicleId: string) => handleFilter(persistedFromDate, persistedToDate, vehicleId)}
                                VehiclesList={vehicles}
                            /> :
                            <div className="flex justify-between items-center">
                                <Skeleton active avatar/>
                                <Skeleton active/>
                            </div>}
                    </Card>
                </div>
                <div className="md:w-9/12 w-full">
                    {loadingEarningsReport && (
                        <div className="flex justify-between items-center">
                            <Skeleton active avatar/>
                            <Skeleton active/>
                        </div>
                    )}
                    <Card bordered={false}>
                        {earningsReport && <div className={styles.stats_boxes}>
                            <ValueBox title="Total revenue" value={`N$ ${earningsReport.totalRevenue.toFixed()}`}/>
                            <ValueBox title="Due to you" tooltipText="Total amount pending" value={`${currency} ${earningsReport.amountDue.toFixed()}`}/>
                            <ValueBox title="Amount received"
                                      value={`${currency} ${earningsReport.amountReceived.toFixed()}`}/>
                            <ValueBox title="Amount deducted"
                                      value={`${currency} ${earningsReport.amountReceived.toFixed()}`}/>
                        </div>}
                    </Card>
                    {!loadingEarningsReport && earningsReport && (<Card bordered={false} className="my-3">
                        <>
                            <div className={styles.title_bar_earnings}>
                                {!loadingEarningsReport && <p className="section_title">{`Monthly revenue`}</p>}

                            </div>
                            {earningsReport && earningsReport.monthlyRevenue && earningsReport.monthlyRevenue.length > 0 ? (
                                <BarChart labelTitle="Revenue" seriesData={earningsReport.monthlyRevenue}/>
                            ) : <div className="text-gray-400 text-center">
                                {!loadingEarningsReport &&
                                    <div><MdMoneyOff size="100" className="mx-auto my-4"/> <h3>You have no earnings by
                                        selected filters.</h3>
                                        {selectedId && persistedFromDate && (
                                            <div onClick={resetFilterVehicle}
                                                 className="text-jc-primary cursor-pointer hover:text-red-500">
                                                Clear filter
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>}
                        </>
                    </Card>)}
                </div>
            </div>

        </DashboardWrapper>
    )
}

export default Earnings;