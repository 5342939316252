import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {getMonthName} from "../../utils/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
);

interface BarChartProps {
    seriesData: any[]
    labelTitle: string
}

export function BarChart({labelTitle, seriesData}: BarChartProps) {
    const options = {
        responsive: true,
        legend: false
    };

    console.log(seriesData)

    const labels = seriesData.map(item => getMonthName(item.date));
    const values = seriesData.map(item => item.totalRevenue);

    const data = {
        labels,
        datasets: [
            {
                label: labelTitle,
                data: values,
                backgroundColor: 'rgba(45, 70, 105, 1)',
            }
        ],
    };
    return <Bar options={options} data={data}/>
}
