import MainApi from "./axiosInstance";
import Urls from "../utils/constants/urls";
import URLS from "../utils/constants/urls";

class DriverService {
    getAllDrivers() {
        return MainApi.get(Urls.drivers)
    }

    deleteDriver(id: string) {
        return MainApi.delete(`${URLS.drivers}/${id}`)
    }

    fetchDriverRatings(id: string) {
        return MainApi.get(`/driver-ratings?driverId=${id}`)
    }

    createDriver(payload: any) {
        return MainApi.post(URLS.drivers, payload)
    }


}

export default new DriverService()